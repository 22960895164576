import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
/* eslint-disable import/no-unresolved */
import * as layoutMap from '../layouts'

const WidgetGroupRenderer = ({ widgetGroup, children }) => {
  if (!widgetGroup) {
    return null
  }
  const { id, type, style={} } = widgetGroup
  if (widgetGroup.show === false) {
    return null
  }

  return (
    <div className={classNames('row', style.className)}>
      {children}
    </div>
  )
}

WidgetGroupRenderer.propTypes = {
  widgetGroup: PropTypes.object
}

export default WidgetGroupRenderer
