/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import shortid from "shortid";
import { merge } from 'lodash'
import { success, error } from "react-notification-system-redux";
import { handleAction } from "../redux/actions/appActionCreators";
import { PAGE_TARGET_BLANK, PAGE_TARGET_SELF } from '../redux/constants/pageConstants'
import {
  DELETE_WIDGET,
  DELETE_WIDGET_TABLE_ROW,
  RESTORE_WIDGET_TABLE_ROW,
  DELETE_WIDGET_TAB_ITEM,
} from "../redux/constants/widgetConstants";
import { removeFilter } from "../redux/actions/filtersActionCreators";
import { socketHandlerMap } from "../redux/actions/socketActionCreators";
import Icon from "./Icon";
import { renderData } from "../helpers/utils";
import { FILTER_TYPE_PAGE } from "../helpers/constants";
import { showConfirmModal } from "./ConfirmDialog";
import consumer from 'channels/consumer';

class Action extends React.PureComponent {
  constructor(props) {
    super(props);

    const defaultClassName = props.schema.type == 'LINK' && !(props.schema.defaultColor === false)

    this.state = {
      actionInProgress: false,
      actionMessage: "",
      channelConnected: false,
      defaultSchema: {
        label: "",
        title: "",
        style: {
          className: defaultClassName ? "primary-link" : "small-dark",
          iconClassName: ""
        },
        refresh: null,
        notifications: {},
      },
      notificationOptions: {
        title: "",
        message: "",
        position: "tc",
        autoDismiss: 3,
      },
    };
  }

  componentDidMount() {
    const { schema, data } = this.props;

    const actionData = data[schema.id];
    const self = this;

    if (schema.type === "WS" && schema.wsChannel) {
      this.channel = consumer.subscriptions.create(
        {
          channel: schema.wsChannel,
          data: actionData,
        },
        {
          connected: function() {
            self.setState({
              channelConnected: true,
            });
          },
          received: function(receivedData) {
            const socketActionHandler = schema.wsChannelHandler
              ? socketHandlerMap[schema.wsChannelHandler]
              : null;
            if (socketActionHandler) {
              const action = {
                schema,
                data: actionData,
                dispatch: self.props.dispatch,
                notificationOptions: self.state.notificationOptions,
              };

              socketActionHandler(action, receivedData, (result) => {
                self.setState(result);
              });
            }
          },
          sendDataToMethod: function(method, payload) {
            this.perform(method, payload);
          },
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.channel) {
      this.channel.unsubscribe();
    }

    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  get showTabCloseIcon() {
    const { schema, data, options } = this.props;

    return (
      (schema.type === DELETE_WIDGET_TAB_ITEM &&
        ((data && data[schema.id] && !data[schema.id].deleted) ||
          (options && !options.deleted)))
    );
  }

  get showTabRestoreIcon() {
    const { schema, data, options = {} } = this.props;

    return (
      (schema.type === DELETE_WIDGET_TAB_ITEM &&
        ((data && data[schema.id] && data[schema.id].deleted) ||
          (options.deleted)))
    );
  }

  get showCloseIcon() {
    const { schema, data, options = {} } = this.props;

    return (
      (schema.type === DELETE_WIDGET && !options.deleted) ||
      (schema.type === DELETE_WIDGET_TABLE_ROW &&
        ((data && data[schema.id] && !data[schema.id].deleted) ||
          (options && !options.deleted))) ||
      (schema.type === RESTORE_WIDGET_TABLE_ROW &&
        (data && data[schema.id] && data[schema.id].restored))
    );
  }

  get showRestoreIcon() {
    const { schema, data, options = {} } = this.props;

    return (
      (schema.type === DELETE_WIDGET && options.deleted) ||
      (schema.type === DELETE_WIDGET_TABLE_ROW &&
        ((data && data[schema.id] && data[schema.id].deleted) ||
          (options && options.deleted))) ||
      (schema.type === RESTORE_WIDGET_TABLE_ROW &&
        (data && data[schema.id] && !data[schema.id].restored))
    );
  }

  get isWidgetDirty() {
    const { options = {} } = this.props;

    return options.dirty;
  }

  remoteClick = (remoteActionsSchema) => {
    var evt = new CustomEvent("click", {
      bubbles:true,
      detail: { trigger: "REMOTE", ...remoteActionsSchema.formData }});

    var cb = document.getElementById(remoteActionsSchema.widgetId);
    cb.dispatchEvent(evt);
  }

  handleAction = (e, action) => {
    const { schema, data } = action;
    const remoteDetail = e.detail;

    if(schema.type === "CANCEL") {
      const { options = {} } = this.props
      const { callback } = options

      if (callback) {
        callback({ cancel: true })
      }
      return;
    }

    if(schema.type === "REMOTE_ACTION") {
      // dispatches submit event in another form
      this.remoteClick(schema);
      return;
    }

    if(remoteDetail && remoteDetail.trigger == "REMOTE") {
      action.data.apiPayload = merge(action.data.apiPayload, remoteDetail.append);
    }

    if (schema.type === "API") {
      this.setState({ apiRequesting: true });
    }

    if (schema.type === "WS") {
      this.setState({
        actionInProgress: true,
        actionMessage: "",
      });

      if (schema.wsChannelMethod) {
        this.channel.sendDataToMethod(schema.wsChannelMethod, data.payload);
      } else {
        this.channel.send(data.payload);
      }
      return;
    }

    if (schema.type === 'COPY_TO_CLIPBOARD') {
      const { notifications = {} } = this.props.schema

      const clipboardText = data.text || schema.text || ''
      const $temp = $('<input>');
      $('body').append($temp);
      $temp.val(clipboardText).select();
      document.execCommand('copy');
      $temp.remove();

      if (notifications.success) {
        this.props.dispatch(success({
          ...this.state.notificationOptions,
          title: notifications.success.title,
          message: notifications.success.message
        }))
      }
      return;
    }

    if (schema.type === "SAVE_OR_UPDATE_WIDGET_API") {
      const updateWidgetAction = {
        data,
        schema
      };
      this.props.dispatch(
        handleAction(e, updateWidgetAction, this.onActionComplete)
      );
      return;
    }

    if (schema.type === DELETE_WIDGET) {
      const deleteWidgetAction = {
        data,
        schema,
      };

      this.props.dispatch(
        handleAction(e, deleteWidgetAction, this.onActionComplete)
      );
      return;
    }

    this.props.dispatch(handleAction(e, action, this.onActionComplete));
  };

  onClickHandler(e, action) {
    e.preventDefault();
    // close bootstrap dropdown after selecting an item
    $('.dropdown-menu.show').removeClass('show');

    const { schema, data } = action;
    if (schema.confirmDialog) {
      return showConfirmModal(schema).then((confirm) => {
        if (confirm) {
          this.handleAction(e, action);
        }
      });
    }

    this.handleAction(e, action);
  }

  onActionComplete = (response) => {
    const { notifications = {} } = this.props.schema
    const { options = {}} = this.props
    const { callback } = options

    // if no notification key passed from schema
    if (Object.keys(notifications).length === 0) {
      return false;
    }
    if (response.status === "success") {
      this.props.dispatch(
        success({
          ...this.state.notificationOptions,
          title: notifications.success.title,
        })
      );
      if (callback) {
        callback({ success: true })
      }
    } else {
      this.props.dispatch(
        error({
          ...this.state.notificationOptions,
          title: notifications.error.title,
          message: response.error,
        })
      );
      if (callback) {
        callback({ success: false })
      }
    }
    this.setState({ apiRequesting: false });
  };

  handleActionValidation = () => {
    const { schema, data } = this.props;
    const { disabled, checkActionValidation } = data;
    const { validationScheme } = schema;
    // Do a validation scheme basis check only if the validation scheme is defined
    if (validationScheme) {
      return checkActionValidation(validationScheme);
    }

    if (typeof schema.disabled === "boolean") {
      return schema.disabled;
    }

    return disabled;
  };

  checkCondition = (condition, default_value) => {
    return typeof condition === 'undefined' && default_value || condition
  }

  render() {
    const { schema, data } = this.props;
    const {defaultSchema, apiRequesting, actionInProgress } = this.state
    const {actionMessage, channelConnected } = this.state
    const { pageFilter, widgetFilter, pageId, widget } = this.context;
    const { label, title, style, hide } = schema;
    const actionStyle = style || data.style || {};
    const disabled = this.handleActionValidation();
    const isPageFilter = schema.filterType === FILTER_TYPE_PAGE;
    const filter = isPageFilter ? pageFilter : widgetFilter;
    const actionData = (schema.id ? data[schema.id] : data) || {};
    const apiPayload = data.apiPayload || actionData.apiPayload;

    // ensure that when the actionData.hide is undefined,
    // it falls back to schema default.
    if (this.checkCondition(actionData && actionData.hide, hide)) {
      return null;
    }

    const isText = actionData.label || label || defaultSchema.label;
    const actionLabel =
      apiRequesting || actionInProgress
        ? actionMessage || "Submitting..."
        : actionData.label || label || defaultSchema.label;
    const actionClassName = classNames(
      actionStyle.className || defaultSchema.style.className,
      "text-btn",
      {
        disabled:
          apiRequesting ||
          actionInProgress ||
          disabled ||
          (schema.type === 'WS' && !channelConnected) ||
          (schema.type == 'SAVE_OR_UPDATE_WIDGET_API' && schema.live && !this.isWidgetDirty)
        ,
        'position-relative': schema.badge,
        'primary-link': schema.type == 'LINK' && !(schema.defaultColor === false)
      },
      'cvc-c-action'
    );
    const labelSchema = schema.label || {};
    const iconClassName = actionStyle.iconClassName || defaultSchema.style.iconClassName;
    const iconSchema = {
      className: classNames(
        { "mr-2": iconClassName && labelSchema && isText },
        iconClassName,
        {
          'filter-applied': filter && schema.type == 'FILTER',
          'icon-close': this.showCloseIcon && !(schema.label || schema.title),
          'icon-restore': this.showRestoreIcon && !(schema.label || schema.title),
          'icon-close-small': this.showTabCloseIcon,
          'icon-restore-small': this.showTabRestoreIcon,
        }
      ),
    };

    // For LINK type
    if (schema.displayTag) {
      const { newTab, linkUrl, label } = actionData
      const target = (newTab || schema.newTab) ? PAGE_TARGET_BLANK : PAGE_TARGET_SELF
      const href = linkUrl || schema.linkUrl
      if (href) {
        return <a href={href} target={target} className="primary-link">{label || href}</a>
      }
      return <span>{ label }</span>
    }

    return (
      <a
        id={schema.id}
        key={shortid.generate()}
        className={actionClassName}
        style={{ background: actionStyle.background }}
        role="presentation"
        title={title || defaultSchema.title}
        onClick={(e) => {
          e.stopPropagation();
          this.onClickHandler(e, {
            schema,
            data: {
              ...actionData,
              apiPayload,
              filter: filter
            },
          });
        }}
        {...schema.tooltip}
      >
        {iconSchema.className
          && <Icon schema={iconSchema} />
        }
        {labelSchema && isText && renderData(labelSchema, actionLabel)}
        {
          (schema.type === "FILTER" && filter) ? (
            <Icon
              schema={{
                className: "icons close ml-2",
                title: "Remove Filters",
              }}
              onClickHandler={(e) => {
                e.stopPropagation();
                this.props.dispatch(removeFilter({ filter }));
              }}
            />
          ) : null
        }
        {schema.badge && <span className={
          classNames("action-badge badge badge-pill badge-success", style.badge && style.badge.className)}>
          { actionData && actionData.badge ? actionData.badge : data.badge }
          </span>
        }
      </a>
    );
  }
}

Action.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
    style: PropTypes.object,
    refresh: PropTypes.object,
    notifications: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
};

Action.contextTypes = {
  store: PropTypes.object,
  widgetGroupId: PropTypes.string,
  widgetId: PropTypes.string,
  pageFilter: PropTypes.object,
  widgetFilter: PropTypes.object,
  pageId: PropTypes.string,
  widget: PropTypes.object,
};

export default Action;
