import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import classNames from 'classnames'

import { renderData } from '../helpers/utils'

const ProgressBar = ({ schema, data }) => {
  const defaultData = {
    value: 3,
    label: '',
    title: '',
    barSize: 20
  }

  const { showValue, barSize, minimumValue, className, containerClassName } = schema
  const { title, label, primaryValue, bars } = data

  const tooltip = data.tooltip || schema.tooltip
  const titleSchema = schema.title
  const labelSchema = schema.label
  const defaultMinimumValue = minimumValue != null ? minimumValue : defaultData.value
  const titleToRender = title || defaultData.title // render at top
  const labelToRender = label || defaultData.label // render at bottom

  return (
    <div title={tooltip}>
      { title && (
        <div className="text-center">
          {titleSchema ? renderData(titleSchema, titleToRender) : titleToRender}
        </div>
      )}
      <div className={classNames(containerClassName ? containerClassName : 'd-flex flex-row align-items-center')}>
        <div className="cvc-c-progress w-100">
          { bars.map((bar) => {
            const style = {
              width: `${bar.value > defaultMinimumValue ? bar.value : defaultMinimumValue}%`,
              height: `${barSize || defaultData.barSize}px`,
              backgroundColor: `${bar.background}`
            }

            return <div className="progress-bar" style={style} key={shortid.generate()} />
          })
        }
        </div>
        { (showValue && primaryValue !== undefined && primaryValue !== null) ? (
            <span className="ml-2 d-inline-block progress-value">
              {primaryValue}%
            </span>
          ) : (
            <span>&nbsp;</span>
          )
        }
      </div>
      { label && (
        <div className="mt-1 text-gray">
          {labelSchema ? renderData(labelSchema, labelToRender) : labelToRender}
        </div>
      )}
    </div>
  )
}

ProgressBar.propTypes = {
  schema: PropTypes.shape({
    showValue: PropTypes.bool,
    barSize: PropTypes.number,
    tooltip: PropTypes.string
  }).isRequired,
  data: PropTypes.shape({
    primaryValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bars: PropTypes.array,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tooltip: PropTypes.string
  }).isRequired
}

export default ProgressBar
